export default {
    mobile: {
        home: 'Ara. awal',
        usr_agreement: 'Perjanjian pengguna',
        privacy_agreement: 'Pernyataan privasi',
        account_request: 'Permintaan Penghapusan Akun',
        title: '《 Warisan Tari Pedang 》',
        name: 'Deskripsi permainan',
        info: '《 WarisanTari Pedang 》Ini adalah MMORPG bertema peri oriental. Gim ini mengadopsi penelitian dan pengembangan teknologi mesin Unity 3D, perpaduan elemen batu dan elemen abadi, konsep "batu abadi" pertama. Gim ini kaya akan gameplay, dengan pertempuran kamp, liga abadi, medan perang Tianxuan, medan perang kekacauan, penaklukan iblis hutan belantara, aula jiwa perang dan gameplay PVT dan PVE lainnya, hewan peliharaan, tunggangan, permata, peralatan, dan elemen budidaya lainnya, tetapi juga memperkenalkan obrolan waktu nyata, sistem cinta, foto-foto kecantikan, dan gameplay sosial lainnya.',
        about: 'Hubungi Kami',
        img01: '/img/home13.svg',
        img02: '/img/home15.svg',






    },
}