<template>
  <div>
    <!-- 首页导航 -->
    <div class="header-box">
      <headerBox navTable="2"></headerBox>
    </div>
    <div class="banner">
      <div class="new-header">
        <div class="header-contain">
          <div class="advise">
            <img class="img" src="/img/home11.svg" alt="" />
          </div>
          <!-- 标题 -->
          <div class="head_line">
            <div class="head_line_content">
              <div class="name">{{ $t("mobile.usr_agreement") }}</div>
              <!-- 协议英文 -->
              <template v-if="lang == 'en'">
                <div class="Section0">
                  <strong>Terms &amp; Conditions</strong><br />
                  <p>
                    These terms and conditions applies to the Blade Dance Legacy
                    app (hereby referred to as "Application") for mobile devices
                    that was created by Dawn (HK) TeChnology Co., Limited
                    (hereby referred to as "Service Provider") as a Free
                    service.
                  </p>
                  <br />
                  <p>
                    Upon downloading or utilizing the Application, you are
                    automatically agreeing to the following terms. It is
                    strongly advised that you thoroughly read and understand
                    these terms prior to using the Application. Unauthorized
                    copying, modification of the Application, any part of the
                    Application, or our trademarks is strictly prohibited. Any
                    attempts to extract the source code of the Application,
                    translate the Application into other languages, or create
                    derivative versions are not permitted. All trademarks,
                    copyrights, database rights, and other intellectual property
                    rights related to the Application remain the property of the
                    Service Provider.
                  </p>
                  <br />
                  <p>
                    The Service Provider is dedicated to ensuring that the
                    Application is as beneficial and efficient as possible. As
                    such, they reserve the right to modify the Application or
                    charge for their services at any time and for any reason.
                    The Service Provider assures you that any charges for the
                    Application or its services will be clearly communicated to
                    you.
                  </p>
                  <br />
                  <p>
                    The Application stores and processes personal data that you
                    have provided to the Service Provider in order to provide
                    the Service. It is your responsibility to maintain the
                    security of your phone and access to the Application. The
                    Service Provider strongly advise against jailbreaking or
                    rooting your phone, which involves removing software
                    restrictions and limitations imposed by the official
                    operating system of your device. Such actions could expose
                    your phone to malware, viruses, malicious programs,
                    compromise your phone's security features, and may result in
                    the Application not functioning correctly or at all.
                  </p>
                  <div>
                    <p>
                      Please note that the Application utilizes third-party
                      services that have their own Terms and Conditions. Below
                      are the links to the Terms and Conditions of the
                      third-party service providers used by the Application:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="https://policies.google.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Google Play Services</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://developers.google.com/admob/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          >AdMob</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://www.google.com/analytics/terms/"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Google Analytics for Firebase</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://firebase.google.com/terms/crashlytics"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Firebase Crashlytics</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/legal/terms/plain_text_terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Facebook</a
                        >
                      </li>
                      <!----><!----><!----><!----><!----><!---->
                      <li>
                        <a
                          href="https://unity3d.com/legal/terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Unity</a
                        >
                      </li>
                      <!----><!----><!----><!----><!----><!----><!----><!----><!----><!---->
                      <li>
                        <a
                          href="https://www.adjust.com/terms/general-terms-and-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Adjust</a
                        >
                      </li>
                      <!----><!----><!----><!----><!----><!----><!----><!----><!---->
                    </ul>
                  </div>
                  <br />
                  <p>
                    Please be aware that the Service Provider does not assume
                    responsibility for certain aspects. Some functions of the
                    Application require an active internet connection, which can
                    be Wi-Fi or provided by your mobile network provider. The
                    Service Provider cannot be held responsible if the
                    Application does not function at full capacity due to lack
                    of access to Wi-Fi or if you have exhausted your data
                    allowance.
                  </p>
                  <br />
                  <p>
                    If you are using the application outside of a Wi-Fi area,
                    please be aware that your mobile network provider's
                    agreement terms still apply. Consequently, you may incur
                    charges from your mobile provider for data usage during the
                    connection to the application, or other third-party charges.
                    By using the application, you accept responsibility for any
                    such charges, including roaming data charges if you use the
                    application outside of your home territory (i.e., region or
                    country) without disabling data roaming. If you are not the
                    bill payer for the device on which you are using the
                    application, they assume that you have obtained permission
                    from the bill payer.
                  </p>
                  <br />
                  <p>
                    Similarly, the Service Provider cannot always assume
                    responsibility for your usage of the application. For
                    instance, it is your responsibility to ensure that your
                    device remains charged. If your device runs out of battery
                    and you are unable to access the Service, the Service
                    Provider cannot be held responsible.
                  </p>
                  <br />
                  <p>
                    In terms of the Service Provider's responsibility for your
                    use of the application, it is important to note that while
                    they strive to ensure that it is updated and accurate at all
                    times, they do rely on third parties to provide information
                    to them so that they can make it available to you. The
                    Service Provider accepts no liability for any loss, direct
                    or indirect, that you experience as a result of relying
                    entirely on this functionality of the application.
                  </p>
                  <br />
                  <p>
                    The Service Provider may wish to update the application at
                    some point. The application is currently available as per
                    the requirements for the operating system (and for any
                    additional systems they decide to extend the availability of
                    the application to) may change, and you will need to
                    download the updates if you want to continue using the
                    application. The Service Provider does not guarantee that it
                    will always update the application so that it is relevant to
                    you and/or compatible with the particular operating system
                    version installed on your device. However, you agree to
                    always accept updates to the application when offered to
                    you. The Service Provider may also wish to cease providing
                    the application and may terminate its use at any time
                    without providing termination notice to you. Unless they
                    inform you otherwise, upon any termination, (a) the rights
                    and licenses granted to you in these terms will end; (b) you
                    must cease using the application, and (if necessary) delete
                    it from your device.
                  </p>
                  <br /><strong>Changes to These Terms and Conditions</strong>
                  <p>
                    The Service Provider may periodically update their Terms and
                    Conditions. Therefore, you are advised to review this page
                    regularly for any changes. The Service Provider will notify
                    you of any changes by posting the new Terms and Conditions
                    on this page.
                  </p>
                  <br />
                  <p>
                    These terms and conditions are effective as of 2024-09-12
                  </p>
                  <br /><strong>Contact Us</strong>
                  <p>
                    If you have any questions or suggestions about the Terms and
                    Conditions, please do not hesitate to contact the Service
                    Provider at kuaiyou878@gmail.com.
                  </p>
                </div>
              </template>
              <!-- 协议印尼 -->
              <template v-else>
                <div class="Section0">
                  <strong>Syarat & Ketentuan</strong><br />
                  <p>
                    Syarat dan ketentuan ini berlaku untuk aplikasi Blade Dance
                    Legacy (selanjutnya disebut sebagai "Aplikasi") untuk
                    perangkat seluler yang dibuat oleh Dawn (HK) TeChnology Co.,
                    Limited (selanjutnya disebut sebagai "Penyedia Layanan")
                    sebagai layanan Gratis.
                  </p>
                  <br />
                  <p>
                    Setelah mengunduh atau menggunakan Aplikasi, Anda secara
                    otomatis menyetujui ketentuan berikut. Sangat disarankan
                    agar Anda membaca dan memahami ketentuan ini secara
                    menyeluruh sebelum menggunakan Aplikasi. Dilarang keras
                    menyalin, mengubah Aplikasi, bagian mana pun dari Aplikasi,
                    atau merek dagang kami tanpa izin. Segala upaya untuk
                    mengekstrak kode sumber Aplikasi, menerjemahkan Aplikasi ke
                    bahasa lain, atau membuat versi turunan tidak diizinkan.
                    Semua merek dagang, hak cipta, hak basis data, dan hak
                    kekayaan intelektual lainnya yang terkait dengan Aplikasi
                    tetap menjadi milik Penyedia Layanan.
                  </p>
                  <br />
                  <p>
                    Penyedia Layanan berkomitmen untuk memastikan bahwa Aplikasi
                    bermanfaat dan seefisien mungkin. Oleh karena itu, mereka
                    berhak mengubah Aplikasi atau mengenakan biaya untuk layanan
                    mereka kapan saja dan untuk alasan apa pun. Penyedia Layanan
                    meyakinkan Anda bahwa setiap biaya untuk Aplikasi atau
                    layanannya akan dikomunikasikan dengan jelas kepada Anda.
                  </p>
                  <br />
                  <p>
                    Aplikasi menyimpan dan memproses data pribadi yang telah
                    Anda berikan kepada Penyedia Layanan untuk menyediakan
                    Layanan. Anda bertanggung jawab untuk menjaga keamanan
                    ponsel dan akses ke Aplikasi. Penyedia Layanan sangat
                    menyarankan agar tidak melakukan jailbreak atau rooting
                    ponsel Anda, yang melibatkan penghapusan batasan dan
                    pembatasan perangkat lunak yang diberlakukan oleh sistem
                    operasi resmi perangkat Anda. Tindakan tersebut dapat
                    membuat ponsel Anda rentan terhadap malware, virus, program
                    jahat, membahayakan fitur keamanan ponsel Anda, dan dapat
                    mengakibatkan Aplikasi tidak berfungsi dengan benar atau
                    tidak berfungsi sama sekali.
                  </p>
                  <div>
                    <p>
                      Harap diperhatikan bahwa Aplikasi menggunakan layanan
                      pihak ketiga yang memiliki Syarat dan Ketentuannya
                      sendiri. Berikut adalah tautan ke Syarat dan Ketentuan
                      penyedia layanan pihak ketiga yang digunakan oleh
                      Aplikasi:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="https://policies.google.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Layanan Google Play</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://developers.google.com/admob/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          >AdMob</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://www.google.com/analytics/terms/"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Google Analytics untuk Firebase</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://firebase.google.com/terms/crashlytics"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Firebase Crashlytics</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/legal/terms/plain_text_terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Facebook</a
                        >
                      </li>
                      <!----><!----><!-----><!-----><!----->
                      <li>
                        <a
                          href="https://unity3d.com/legal/terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Unity</a
                        >
                      </li>
                      <!----><!----><!----><!----><!----><!----><!----><!----><!----><!---->
                      <li>
                        <a
                          href="https://www.adjust.com/terms/general-terms-and-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Sesuaikan</a
                        >
                      </li>
                      <!----><!----><!----><!----><!----><!----><!----><!---->
                    </ul>
                  </div>
                  <br />
                  <p>
                    Harap diperhatikan bahwa Penyedia Layanan tidak bertanggung
                    jawab atas aspek-aspek tertentu. Beberapa fungsi Aplikasi
                    memerlukan koneksi internet aktif, yang dapat berupa Wi-Fi
                    atau disediakan oleh penyedia jaringan seluler Anda.
                    Penyedia Layanan tidak dapat bertanggung jawab jika Aplikasi
                    tidak berfungsi dengan kapasitas penuh karena kurangnya
                    akses ke Wi-Fi atau jika Anda telah menghabiskan kuota data
                    Anda.
                  </p>
                  <br />
                  <p>
                    Jika Anda menggunakan aplikasi di luar area Wi-Fi, harap
                    diperhatikan bahwa ketentuan perjanjian penyedia jaringan
                    seluler Anda masih berlaku. Akibatnya, Anda mungkin
                    dikenakan biaya dari penyedia seluler Anda untuk penggunaan
                    data selama koneksi ke aplikasi, atau biaya pihak ketiga
                    lainnya. Bahasa Indonesia: Dengan menggunakan aplikasi, Anda
                    menerima tanggung jawab atas biaya tersebut, termasuk biaya
                    roaming data jika Anda menggunakan aplikasi di luar wilayah
                    asal Anda (misalnya, kawasan atau negara) tanpa
                    menonaktifkan roaming data. Jika Anda bukan pembayar tagihan
                    untuk perangkat yang Anda gunakan aplikasi, mereka berasumsi
                    bahwa Anda telah memperoleh izin dari pembayar tagihan.
                  </p>
                  <br />
                  <p>
                    Demikian pula, Penyedia Layanan tidak selalu dapat memikul
                    tanggung jawab atas penggunaan aplikasi oleh Anda. Misalnya,
                    Anda bertanggung jawab untuk memastikan perangkat Anda tetap
                    terisi daya. Jika perangkat Anda kehabisan baterai dan Anda
                    tidak dapat mengakses Layanan,Penyedia Layanan tidak dapat
                    bertanggung jawab.
                  </p>
                  <br />
                  <p>
                    Sehubungan dengan tanggung jawab Penyedia Layanan atas
                    penggunaan aplikasi oleh Anda, penting untuk dicatat bahwa
                    meskipun mereka berusaha untuk memastikan bahwa aplikasi
                    selalu diperbarui dan akurat, mereka mengandalkan pihak
                    ketiga untuk memberikan informasi kepada mereka sehingga
                    mereka dapat menyediakannya untuk Anda. Penyedia Layanan
                    tidak bertanggung jawab atas kerugian, langsung atau tidak
                    langsung, yang Anda alami sebagai akibat dari mengandalkan
                    sepenuhnya pada fungsi aplikasi ini.
                  </p>
                  <br />
                  <p>
                    Penyedia Layanan mungkin ingin memperbarui aplikasi di
                    beberapa titik. Aplikasi saat ini tersedia sesuai dengan
                    persyaratan untuk sistem operasi (dan untuk sistem tambahan
                    apa pun yang mereka putuskan untuk memperluas ketersediaan
                    aplikasi) dapat berubah, dan Anda perlu mengunduh pembaruan
                    jika Anda ingin terus menggunakan aplikasi. Penyedia Layanan
                    tidak menjamin bahwa mereka akan selalu memperbarui aplikasi
                    sehingga relevan bagi Anda dan/atau kompatibel dengan versi
                    sistem operasi tertentu yang diinstal pada perangkat Anda.
                    Namun, Anda setuju untuk selalu menerima pembaruan aplikasi
                    saat ditawarkan kepada Anda. Penyedia Layanan juga dapat
                    berhenti menyediakan aplikasi dan dapat menghentikan
                    penggunaannya kapan saja tanpa memberikan pemberitahuan
                    penghentian kepada Anda. Kecuali jika mereka memberi tahu
                    Anda sebaliknya, setelah penghentian apa pun, (a) hak dan
                    lisensi yang diberikan kepada Anda dalam ketentuan ini akan
                    berakhir; (b) Anda harus berhenti menggunakan aplikasi, dan
                    (jika perlu) menghapusnya dari perangkat Anda.
                  </p>
                  <br /><strong>Perubahan pada Syarat dan Ketentuan Ini</strong>
                  <p>
                    Penyedia Layanan dapat memperbarui Syarat dan Ketentuan
                    mereka secara berkala. Oleh karena itu, Anda disarankan
                    untuk meninjau halaman ini secara berkala untuk setiap
                    perubahan. Penyedia Layanan akan memberi tahu Anda tentang
                    setiap perubahan dengan mengeposkan Syarat dan Ketentuan
                    baru di halaman ini.
                  </p>
                  <br />
                  <p>Syarat dan ketentuan ini berlaku mulai 2024-09-12</p>
                  <br /><strong>Hubungi Kami</strong>
                  <p>
                    Jika Anda memiliki pertanyaan atau saran tentang Syarat dan
                    Ketentuan, jangan ragu untuk menghubungi Penyedia Layanan di
                    kuaiyou878@gmail.com.
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="footer-box">
      <footerBox></footerBox>
    </div>
    <!-- 回到头部小图片 -->
    <toTop></toTop>
  </div>
</template>
<script>
import headerBox from "../components/Header-box/header-box.vue";
import footerBox from "../components/Footer-box/footer.vue";
import toTop from "../components/totop/totop.vue";

export default {
  name: "Mobile",
  components: {
    headerBox,
    footerBox,
    toTop,
  },
  data() {
    return {
      lang: "",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
  },
};
</script>

<style lang="less" scoped>
.advise {
  .img {
    width: 100%;
    height: auto;
    object-fit: contain;
    cursor: default;
  }
}
// 标题
.head_line {
  padding-top: 36px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .head_line_content {
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .name {
      font-weight: bold;
      font-size: 32px;
      color: #000000;
    }
  }
}
//协议
.Section0 {
  padding: 32px 0;
  a {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
