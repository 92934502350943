<template>
  <div class="footer">
    <div class="footer_content">
      <div class="left">
        <img src="/img/home01.svg" />
        <br />
        <p class="text">
          <a href="/AccountDeletionRequest.html">{{ $t("mobile.about") }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "Footer",
  props: {
    ishome: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    QRCode,
  },
  data() {
    return {};
  },
  methods: {
    gohomePage() {
      let ext = this.$cookies.get("aiseo_channel_ext");
      if (ext) {
        window.open("https://www.aiseo.net?ext=" + ext);
      } else {
        window.open("https://www.aiseo.net");
      }
    },
  },
};
</script>
