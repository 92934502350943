<template>
  <div class="header-box">
    <!-- 顶部 -->
    <div class="header-wrap" v-bind:style="fixed">
      <div class="header">
        <div class="navbar-header">
          <a class="navbar-brand" href="/"
            ><img class="img" :src="$t('mobile.img01')"
          /></a>
        </div>
        <ul class="navbar_nav">
          <li :class="navTable == 1 ? 'active' : ''">
            <router-link to="/home">{{ $t("mobile.home") }}</router-link>
          </li>
          <li :class="navTable == 2 ? 'active' : ''">
            <router-link to="/user-agreement">{{
              $t("mobile.usr_agreement")
            }}</router-link>
          </li>
          <li :class="navTable == 3 ? 'active' : ''">
            <router-link to="/privacy-agreement">{{
              $t("mobile.privacy_agreement")
            }}</router-link>
          </li>
          <li :class="navTable == 4 ? 'active' : ''">
            <a href="/AccountDeletionRequest.html">{{
              $t("mobile.account_request")
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { channelInfo, configInfo } from "@/api/customer";
import QRCode from "qrcodejs2";
export default {
  name: "Header-box",
  components: {
    QRCode,
  },
  props: {
    navTable: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      fixed: "",
      src1: "/img/home-logo.png",
      //代理商id
      cid: "",
      //体验价
      registerCash: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>
