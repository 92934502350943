import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入组件库
import Home from '../views/Home.vue'
import Mobile from '../views/Mobile.vue'
import UserAgreement from '../views/UserAgreement.vue'
import PrivacyAgreement from '../views/PrivacyAgreement.vue'
import Contact from '../views/Contact.vue'

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/mobile',
    component: Home
  },
  {
    path: '/user-agreement',
    name: "UserAgreement",
    component: UserAgreement
  },
  {
    path: '/privacy-agreement',
    name: "PrivacyAgreement",
    component: PrivacyAgreement
  },
  {
    path: '/contact',
    name: "Contact",
    component: Contact
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否则，默认滚动到页面顶部
      return { x: 0, y: 0 };
    }
  }
})

export default router
